import React from 'react';
import { Link } from 'gatsby';

import Layout from '@components/layout';
import SEO from '@components/seo';
import { PortableTextBlock } from '@modules/portableTextBlock';
import { useSiteConfig } from '@querys/useSiteConfig';

import Exit from '@svg/exit.svg';

const Terms = () => {
  const { termsPopup } = useSiteConfig();

  return (
    <Layout>
      <SEO title='Terms & Conditions' />
      <Link to='/promo'>
        <Exit className='ml-auto mt-6 mr-6 md:mt-12 md:mr-12 w-6 md:w-12' />
      </Link>
      <div className='terms grid grid-cols-14 bg-liaise-orange py-12 md:py-32'>
        <div className='col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 prose md:prose-lg max-w-none'>
          {termsPopup.heading && (
            <h3 className='text-center'>{termsPopup.heading}</h3>
          )}
          {termsPopup.subheading && (
            <h5 className='pb-12 block text-center'>{termsPopup.subheading}</h5>
          )}
          <PortableTextBlock text={termsPopup.copy} />
        </div>

        <div className='col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 mt-12'>
          <Link className='underline mt-5 font-bold' to='/promo'>
            Back to Liaise website
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
